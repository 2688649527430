import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-grid mt-12" }
const _hoisted_2 = { class: "d-grid mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PillInputEmail = _resolveComponent("PillInputEmail")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendInvite && _ctx.sendInvite(...args)), ["prevent"]))
    }, [
      _createVNode(_component_FormGroup, {
        errors: _ctx.team.errors,
        "help-text": _ctx.$t('sentences.inputEmailSeparatedBy')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PillInputEmail, {
            modelValue: _ctx.emails,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emails) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["errors", "help-text"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BtnResource, {
          "data-test-id": "btn-send",
          resource: _ctx.team,
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('teams.invite.actions.send')), 1)
          ]),
          _: 1
        }, 8, ["resource"])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn",
        "data-test-id": "btn-skip",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('redirect')))
      }, _toDisplayString(_ctx.$t('teams.invite.actions.skip')), 1)
    ])
  ]))
}