import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1197c98a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "form-pill row py-1 m-n4" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "px-1 ms-4" }
const _hoisted_5 = { class: "pill-item px-0 col gx-0" }
const _hoisted_6 = { class: "h-0 invisible whitespace-nowrap overflow-x-hidden" }
const _hoisted_7 = ["id", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("label", {
    class: "form-control",
    for: _ctx.name
  }, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (listItem, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: listItem.item,
          class: _normalizeClass(["pill-item col-auto d-flex align-items-center btn btn-sm btn-outline-light", {'border-danger': listItem.error}])
        }, [
          _createElementVNode("div", {
            class: "fs-6 text-truncate",
            title: listItem.item
          }, _toDisplayString(listItem.item), 9, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Fa, {
              class: "text-black-50",
              icon: "cross-small",
              size: "xs",
              onClick: ($event: any) => (_ctx.removeItem(index))
            }, null, 8, ["onClick"])
          ])
        ], 2))
      }), 128)),
      _createElementVNode("li", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.input), 1),
        _withDirectives(_createElementVNode("input", {
          id: _ctx.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
          autocomplete: "off",
          class: "w-100 h-100 border-0 outline-0",
          placeholder: _ctx.isEmpty ? _ctx.placeholder : null,
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addSingleItem && _ctx.addSingleItem(...args))),
          onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeyPress && _ctx.handleKeyPress(...args))),
          onPaste: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.paste && _ctx.paste(...args)))
        }, null, 40, _hoisted_7), [
          [_vModelText, _ctx.input]
        ])
      ])
    ])
  ], 8, _hoisted_1))
}