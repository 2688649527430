
import {PropType, defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import FormGroup from '@/components/common/form/FormGroup.vue';
import {ListItem} from '@/components/common/form/PillInput.vue';
import PillInputEmail from '@/components/common/form/PillInputEmail.vue';
import Role from '@/library/enumerations/Role';
import Team from '@/models/Team';

export default defineComponent({
    components: {
        BtnResource,
        FormGroup,
        PillInputEmail,
    },
    props: {
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
    emits: [
        'redirect',
    ],
    setup() {
        return {
            emails: ref<ListItem[]>([]),
        };
    },
    methods: {
        assignEmailErrors() {
            Object.keys(this.team.errors.errors).forEach((key: string) => {
                const match = key.match(/teamUsers\.sync\.(\d+)\.email/);

                if (!match) return;

                // The group match is the second item in the match array.
                const index = +match[1];

                this.emails[index].error = true;
            });
        },
        async sendInvite() {
            this.emails.forEach((listItem: ListItem) => (listItem.error = false));

            this.team.set('sync', this.emails.map((listItem: ListItem) => ({
                email: listItem.item,
                /*
                 * This component is user to invite editors for a team,
                 * so the role should be editor.
                 */
                roles: [Role.EDITOR],
            })));

            try {
                await this.team.update();
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                this.assignEmailErrors();

                return;
            }

            this.$emit('redirect');
        },
    },
});
