import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PillInput = _resolveComponent("PillInput")!

  return (_openBlock(), _createBlock(_component_PillInput, {
    "input-value": _ctx.inputValue,
    "model-value": _ctx.modelValue,
    "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputValue = $event)),
    "onUpdate:modelValueAdd": _ctx.addSingleEmail,
    "onUpdate:modelValuePaste": _ctx.pasteEmails,
    "onUpdate:modelValueRemove": _ctx.removeEmail
  }, null, 8, ["input-value", "model-value", "onUpdate:modelValueAdd", "onUpdate:modelValuePaste", "onUpdate:modelValueRemove"]))
}