import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "mt-4 mb-12 text-black-50 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInvites = _resolveComponent("FormInvites")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t(`${_ctx.type}.invite.title`)), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t(`${_ctx.type}.invite.body`)), 1),
    _createVNode(_component_FormInvites, {
      team: _ctx.team,
      onRedirect: _ctx.toNextStep
    }, null, 8, ["team", "onRedirect"])
  ]))
}