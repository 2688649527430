
import {PropType, defineComponent, ref} from 'vue';
import FormInvites from '@/components/teams/FormInvites.vue';
import Team from '@/models/Team';
import TeamType from '@/library/enumerations/TeamType';
import onboardingStore from '@/store/onboarding';

export default defineComponent({
    components: {
        FormInvites,
    },
    props: {
        baseTeam: {
            type: Object as PropType<Team>,
            required: true,
        },
        teamType: {
            type: Number as PropType<TeamType>,
            required: true,
        },
    },
    setup(props) {
        return {
            team: ref(props.baseTeam),
        };
    },
    computed: {
        type(): string {
            switch (this.teamType) {
                case TeamType.ORGANISATION:
                    return 'organisations';
                default:
                    return 'teams';
            }
        },
    },
    created() {
        this.team.resetStatus();
    },
    methods: {
        toNextStep() {
            switch (this.teamType) {
                case TeamType.TEAM:
                    this.toAssignPitcher();
                    return;
                default:
                    this.toDashboard();
            }
        },
        toAssignPitcher() {
            this.$router.push(this.team.getLocation('register.assignPitcher'));
        },
        toDashboard() {
            onboardingStore.showOnboarding.value = true;

            this.$router.push({name: 'home'});
        },
    },
});
