
import {PropType, defineComponent} from 'vue';

export interface ListItem {
    item: string;
    error?: boolean;
}

/**
 * Shows a pill input component.
 * Takes user input and separates input on space, tab or enter press to create
 * pills with the split parts.
 * The pills have a button with which they can be deleted from the input.
 * Emits 'update:inputValue' when the input value has changed.
 * Emits 'update:modelValue' when the pills have changed.
 * Emits 'update:modelValueAdd' when a new pill has been added.
 * Emits 'update:modelValuePaste' when new content was pasted in the input.
 * Emits 'update:modelValueRemove' when a pill has been removed.
 */
export default defineComponent({
    props: {
        inputValue: {
            type: String,
            required: true,
        },
        maxItems: {
            type: Number,
            default: Infinity,
        },
        modelValue: {
            type: Array as PropType<ListItem[]>,
            required: true,
        },
        name: {
            type: String,
            default: 'pill-input',
        },
        placeholder: {
            type: String,
            default: null,
        },
    },
    emits: [
        'update:inputValue',
        'update:modelValue',
        'update:modelValueAdd',
        'update:modelValuePaste',
        'update:modelValueRemove',
    ],
    computed: {
        input: {
            get() {
                return this.inputValue;
            },
            set(newValue: string) {
                this.$emit('update:inputValue', newValue);
            },
        },
        isEmpty(): boolean {
            return !this.modelValue.length
                && this.inputValue === '';
        },
    },
    methods: {
        async addSingleItem(event: KeyboardEvent|FocusEvent) {
            event.preventDefault();

            this.$emit('update:modelValue', [...this.modelValue, {item: this.input}]);
            this.$emit('update:modelValueAdd', {item: this.input});

            /*
             * Blur the input when the maxItems is reached.
             * We have to subtract 1 from maxItems, because the emit has not yet been handled,
             * so the modelValue length hasn't changed.
             */
            if (
                event instanceof KeyboardEvent
                && this.modelValue.length >= this.maxItems - 1
            ) {
                (event.target as HTMLInputElement).blur();
            }
        },
        handleKeyPress(event: KeyboardEvent) {
            if (
                this.modelValue.length >= this.maxItems
                && event.code !== 'Backspace'
            ) {
                event.preventDefault();
                return;
            }

            switch (event.code) {
                case 'Enter':
                    event.preventDefault();
                case 'Space':
                case 'Tab':
                    this.addSingleItem(event);
                    break;
                case 'Backspace':
                    if (!this.input) {
                        event.preventDefault();

                        this.removeLastItem();
                    }
                    break;
                default:
                    break;
            }
        },
        paste(event: ClipboardEvent) {
            const pasteText = event.clipboardData?.getData('text');

            if (!pasteText) {
                return;
            }

            event.preventDefault();

            const pasteList = pasteText.split(/\s+/).map((item: string) => ({
                item,
            }));

            this.$emit('update:modelValue', [...this.modelValue, ...pasteList]);
            this.$emit('update:modelValuePaste', pasteList);
        },
        removeLastItem() {
            if (!this.modelValue.length) return;

            const itemsCopy = [...this.modelValue];
            const lastItem = itemsCopy.pop();

            this.$emit('update:modelValue', itemsCopy);
            this.$emit('update:modelValueRemove', this.modelValue.length - 1);

            if (lastItem) {
                this.input = lastItem.item;
            }
        },
        removeItem(index: number) {
            const itemsCopy = [...this.modelValue];

            itemsCopy.splice(index, 1);

            this.$emit('update:modelValue', itemsCopy);
            this.$emit('update:modelValueRemove', index);
        },
    },
});
