
import PillInput, {ListItem} from '@/components/common/form/PillInput.vue';
import {PropType, defineComponent, ref} from 'vue';
import config from '@/library/data/config';
import {uniqBy} from 'lodash';

/**
 * Shows a pill input email component.
 * Makes use of the Pill Input component.
 * Functions like the Pill Input component but sanitizes the input to extract
 * valid email addresses from it.
 */
export default defineComponent({
    components: {
        PillInput,
    },
    props: {
        modelValue: {
            type: Array as PropType<ListItem[]>,
            required: true,
        },
        name: {
            type: String,
            default: 'pill-input-email',
        },
    },
    emits: [
        'update:modelValue',
    ],
    setup() {
        return {
            inputValue: ref<string>(''),
        };
    },
    methods: {
        addSingleEmail(item: ListItem) {
            if (!this.isEmail(item.item)) {
                this.inputValue = item.item;

                return;
            }

            const existingEmail = this.findDuplicateEmail(this.modelValue, item);

            if (!existingEmail) {
                this.$emit('update:modelValue', [...this.modelValue, item]);
            }

            this.inputValue = '';
        },
        findDuplicateEmail(emailList: ListItem[], item: ListItem) {
            return emailList.find((listItem: ListItem) => listItem.item === item.item);
        },
        isEmail(email: string) {
            return email.match(config.EMAIL_REGEX);
        },
        pasteEmails(pasteList: ListItem[]) {
            const itemsToAdd: ListItem[] = [];

            const unmatchingItems = pasteList
                .reduce((items: string[], listItem: ListItem) => {
                    if (!this.isEmail(listItem.item)) {
                        items.push(listItem.item);

                        return items;
                    }

                    const existingEmail = this.findDuplicateEmail(this.modelValue, listItem);

                    if (!existingEmail) {
                        itemsToAdd.push(listItem);
                    }

                    return items;
                }, []);

            this.inputValue += unmatchingItems.join(' ');

            this.$emit('update:modelValue', [
                ...this.modelValue,
                ...uniqBy(itemsToAdd, 'item'),
            ]);
        },
        removeEmail(index: number) {
            const itemsCopy = [...this.modelValue];

            itemsCopy.splice(index, 1);

            this.$emit('update:modelValue', itemsCopy);
        },
    },
});
